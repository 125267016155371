@font-face {
  font-family: LoRes;
  src: url(./assets/fonts/LoRes9MinusOTWide-Regular.ttf);
}

@font-face {
  font-family: Robot;
  src: url(./assets/fonts/Robot9000Italic.ttf);
}

html {
  background: #b3e2f2;
}

.leaflet-container {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0px;

  background: url("./assets/SKY-BG.png") 200%;
}

.leaflet-popup-content-wrapper {
  background: #ba180a;
  color: white;
  box-shadow: 0 3px 14px #ba272766;
}

.leaflet-container a.leaflet-popup-close-button {
  color: white;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #e7b805;
}

.leaflet-container a.leaflet-popup-close-button {
  color: #e7b805;
}

.leaflet-popup-tip {
  background: #ba180a;
}

.under {
  position: absolute;
  text-align: center;
  display: inline-block;
  width: 100%;
  top: 50%;
  font-family: "LoRes";
  font-size: 50px;
}

.leaflet-popup-content {
  text-align: center;
  font-family: "LoRes";
}

.nav {
  /* width: 100%; */
  height: fit-content;
  z-index: 30;
  width: 100%;
  display: grid;
  position: relative;
}

.cafe-logo {
  height: fit-content;
  justify-self: start;
  display: flex;
  grid-column-start: 1;
}

.cafe-nav {
  height: fit-content;
  padding-top: 0.5rem;
  padding-right: 4rem;
  grid-column-start: 2;
  justify-self: end;
  align-self: middle;
  display: flex;
  color: black;
  font-family: "LoRes";
  cursor: pointer;
  font-size: 1.3rem;
  text-decoration: none;
}

@media (max-width: 600px) {
  .cafe-nav {
    padding-right: 1rem;
  } }

.cafe-nav:hover {
  color: #ba180a;
}

.leaflet-bar a.leaflet-disabled {
  background-color: #ba180a;
  color: white;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-bar a {
  background-color: #ba180a;
  color: white;
}

.leaflet-bar a:hover {
  background-color: #e7b805;
  color: white;
}

.leaflet-top .leaflet-control {
  margin-top: 50px;
  margin-left: 20px;
  padding: 10px;
}

.logo {
  width: 50px;
}

.markerInfo {
  display: block;
  position: absolute;
  width: 27%;
  height: 300px;
  padding: 20px;
  z-index: 30;
  margin: 2rem;
  height: fit-content;
  display: block;
  position: absolute;
  background-color: #ba180a;
  bottom: 1rem;
  font-family: "LoRes";
  cursor: grab;
  visibility: visible;
}

@media (max-width: 640px) {
  .markerInfo {
    width: fit-content;
  }
}

.invisible {
  visibility: hidden;
}

.infoTitle {
  color: #e7b805;
}

.infoDescription {
  color: white;
}

.cafe {
  width: 100vw;
  margin-top: 50px;
  position: relative;
  left: -8px;
}

.Main {
  min-height: 100vh;
}

.footer {
  font-family: "LoRes";
  text-align: center;
  width: 100%;
  height: 10vh;
  color: white;
  align-items: center;
  position: relative;
  display: inline-block;
  bottom: 0px;
}

.twitter {
  width: 20px;
}

.cross {
  position: absolute;
  align-items: center;
  width: 30px;
  height: 30px;
  top: 15px;
  right: 10px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enterButton {
  font-family: "LoRes";
  color: #ba180a;
  background: #e7b805;
  border: none;
  width: fit-content;
  height: 30px;
  margin-top: 0.5rem;
  white-space: "no-wrap";
}

.enterButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media (max-width: 780px) {
  .enterButton {
    width: fit-content;
  }
}

.st0 {
  fill: none;
}
.st1 {
  fill: #fefefe;
}
.st2 {
  fill: #012000;
}

.couch {
  fill: #012000;
}

.couch2 {
  fill: #51519b;
}

.couch3 {
  fill: none;
}

.couch4 {
  fill: #b7a0c6;
}

.couch5 {
  fill: #ebd5ef;
}

.couch6 {
  fill: #867295;
}

.couch7 {
  fill: #6a537b;
}

.couch8 {
  fill: #b9a2c8;
}

.couch9 {
  fill: #b9a2c8;
}

.hover {
  fill: #ba180a;
}

.clicked {
  fill: #e7b805;
}

.digitalax {
  font-size: 10px;
  cursor: pointer;
}

a:visited {
  color: #ba180a;
}

a:active {
  color: #e7b805;
}

.st3 {
  fill: #b9a2c8;
}
.st4 {
  fill: #b7a0c6;
}
.st5 {
  fill: #6a537b;
}
.st6 {
  fill: none;
  stroke: #b9a2c8;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st7 {
  fill: #c9b3d5;
}
.st8 {
  fill: #bda6cb;
}
.st9 {
  fill: #d1c0dc;
}
.st10 {
  fill: #fee4fe;
}
.st11 {
  fill: #c4add1;
}
.st12 {
  fill: #ebd5ef;
}
.st13 {
  fill: #8e749c;
}

.st14 {
  fill: #867295;
}
.st15 {
  fill: #ddc3ed;
}
.st16 {
  fill: #765e87;
}
.st17 {
  fill: none;
  stroke: #ebd5ef;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st18 {
  fill: none;
  stroke: #6a537b;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st19 {
  fill: none;
  stroke: #bda6cb;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st20 {
  fill: none;
  stroke: #b7a0c6;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st21 {
  fill: none;
  stroke: #fee4fe;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st22 {
  fill: none;
  stroke: #c9b3d5;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st23 {
  fill: none;
  stroke: #c4add1;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st24 {
  fill: none;
  stroke: #867295;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st25 {
  fill: none;
  stroke: #d1c0dc;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st26 {
  fill: none;
  stroke: #8e749c;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st27 {
  fill: none;
  stroke: #012000;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st28 {
  fill: #51519b;
}
